<template>
  <div>
    <highcharts :options="chartOptions" ref="highcharts" />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';

ExportingHighcharts(Highcharts);

export default {
  name: 'donut-chart',
  props: {
    outerLevel: Array,
    innerLevel: Array,
    title: String,
    outerLevelName: String,
    innerLevelName: String,
  },
  watch: {
    outerLevel(val) {
      this.$refs.highcharts.chart.series[1].setData(val, true);
    },
    innerLevel(val) {
      this.$refs.highcharts.chart.series[0].setData(val, true);
    }
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45,
            beta: 0
          }
        },
        title: {
          text: this.title
        },
        plotOptions: {
          pie: {
            depth: 45,
            dataLabels: {
              enabled: true,
              format: '{point.name}: <b>{point.percentage:.1f}%</b>'
            }
          }
        },
        series: [{
          name: this.innerLevelName,
          data: this.innerLevel,
          size: '45%',
          dataLabels: {
            color: '#ffffff',
            distance: '-50%'
          }
        }, {
          name: this.outerLevelName,
          data: this.outerLevel,
          size: '80%',
          innerSize: '60%',
          dataLabels: {
            style: {
              fontWeight: 'normal'
            }
          },
        }],
        credits: {
          enabled: false
        },
        responsive: {
          rules: [{
            condition: {
              maxWidth: 400
            },
            chartOptions: {
              series: [{
              }, {
                dataLabels: {
                  distance: 10,
                  format: '{point.custom.version}',
                }
              }]
            }
          }]
        }
      }
    };
  }
};
</script>