<template>
  <div>
    <highcharts :options="chartOptions" ref="highcharts" />
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';

ExportingHighcharts(Highcharts);

export default {
  name: 'pie-chart',
  props: {
    title: String,
    series: Array,
  },
  watch: {
    title(val) {
      this.chartOptions.title.text = val;
    },
    series(val) {
      this.$refs.highcharts.chart.series[0].setData(val, true);
    },
  },
  components: {
    highcharts: Chart,
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          events: {
          },
        },
        title: {
          text: this.title
        },
        tooltip: {
          formatter: function() {
            return `${this.point.name}: ${this.point.y}`;
          }
        },
        plotOptions: {
          pie: {
            depth: 45,
            dataLabels: {
              enabled: true,
              format: '{point.name}: <b>{point.percentage:.1f}%</b>'
            }
          }
        },
        series: [{
          name: this.innerLevelName,
          data: this.series.data,
          size: '100%',
          dataLabels: {
            distance: '10%'
        }
        }],
        credits: {
          enabled: false
        },
      }
    };
  }
};
</script>

<style scoped>
#container {
  height: 600px;
}
</style>
