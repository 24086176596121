<template>
  <div>
    <highcharts :options="chartOptions" ref="highcharts"></highcharts>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';

ExportingHighcharts(Highcharts);

export default {
  components: {
    highcharts: Chart
  },
  props: {
    title: String,
    series: Array,
    xAxisCategories: Array,
    yAxisTitle: String,
  },
  watch: {
    title(val) {
      this.chartOptions.title.text = val;
    },
    series(val) {
      this.chartOptions.series = val;
    },
    yAxisTitle(val) {
      this.chartOptions.yAxis.title.text = val;
    },
    xAxisCategories(val) {
      this.chartOptions.xAxis.categories = val;
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          height: 700,
        },
        title: {
          text: this.title,
        },
        xAxis: {
          categories: this.xAxisCategories,
          title: {
            text: null
          },
          gridLineWidth: 1,
        },
        yAxis: {
          max: 100,
          title: {
            text: '% compliance'
          },
          gridLineWidth: 0
        },
        tooltip: {
          valueSuffix: '%',
        },
        plotOptions: {
          column: {
            pointPadding: 0.1,
            groupPadding: 0.05,
          },
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          verticalAlign: 'top',
          opacity: 0.5,
          backgroundColor: null,
        },
        credits: {
          enabled: false
        },
        series: this.series
      }
    };
  }
};
</script>