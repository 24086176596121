<template>
  <div class="landing">
    <a @click="handClick" href="" class="report infection-ai soon"><span>Infection AI</span></a>
    <a @click="handClick" href="/analysis/symptom-details" class="report symptom"><span>Symptom Details</span></a>
    <a @click="handClick" href="/analysis/case-frequency/" class="report frequency"><span>Infection Case Frequency</span></a>
    <a @click="handClick" href="/analysis/infection-rates/" class="report rates"><span>Infection Rates</span></a>
    <a @click="handClick" href="/analysis/medication-usage/" class="report medication"><span>Medication Usage</span></a>
    <a @click="handClick" href="/analysis/days-of-therapy/" class="report dot"><span>Days of Therapy</span></a>
    <a @click="handClick" href="/analysis/immunization-rates" class="report immunization"><span>Immunization Rates</span></a>
    <a @click="handClick" href="/analysis/isolation-days" class="report isolation"><span>Isolation Days</span></a>
  </div>
</template>

<script>
export default {
  name: "analysis-landing",
  components: {},
  data() {
    return {};
  },
  methods: {
    handClick(e) {
      e.preventDefault();
      e.stopPropagation();
      this.$router.push(e.currentTarget.getAttribute("href"));
    },
  },
  created() {},
};
</script>
<style scoped>
.landing {
  display: grid;
  grid-template-rows: 250px 250px;
  grid-template-columns: 250px 250px 250px 250px;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
  margin: 40px;
}
.report {
  border: 2px solid #cacaca;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 15px;
  --base-bg: linear-gradient(#f4f4f4, white) no-repeat top left;
  cursor: pointer;
  color: #444;
}
.report:hover {
  border-color: #9d9d9d;
}
.medication {
  background: url("../../assets/pill-svgrepo-com.svg") no-repeat center center / 100px, var(--base-bg);
}
.frequency {
  background: url("../../assets/chart-svgrepo-com.svg") no-repeat center center / 100px, var(--base-bg);
}
.dot {
  background: url("../../assets/annual-calendar-page-svgrepo-com.svg") no-repeat center center / 100px, var(--base-bg);
}
.rates {
  background: url("../../assets/chart-down-svgrepo-com.svg") no-repeat center center / 100px, var(--base-bg);
}
.immunization {
  background: url("../../assets/immunization-rates-vaccine.svg") no-repeat center center / 100px, var(--base-bg);
}
.isolation {
  background: url("../../assets/isolation-days-mask.svg") no-repeat center center / 100px, var(--base-bg);
}
.symptom {
  background: url("../../assets/symptom-svgrepo-com.svg") no-repeat center center / 100px, var(--base-bg);
}
.infection-ai {
  background: url("../../assets/automation-coming-soon-symbol.svg") no-repeat center center / 100px, var(--base-bg);
}

.soon {
  pointer-events: none;
}
.soon span {
  opacity: 0.25;
}
.soon::after {
  content: "Coming soon";
  display: block;
  text-align: center;
  margin-top: 10px;
}
#app .report span {
  position: absolute;
  bottom: 0px;
  font-size: 1.25rem;
  padding: 10px;
  width: 100%;
  text-align: center;
}
</style>
