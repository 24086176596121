<template>
  <div class="medication-usage">
    <filters-section :showLocation="true" :filterDisplayKeys="getFilterKeys()" :moreFilters="global.additionalFilters" :includeUnits="false" :dateRangeStart="global.defaultDateStart" v-on:change="formatFilters"> </filters-section>

    <page-section title="Graph" v-loading="graphSection.loading">
      <template slot="title">
        <span class="label">Group by</span>
        <el-radio-group v-model="graphSection.groupBy" size="small" @change="getSummary">
          <el-radio-button label="Medication"></el-radio-button>
          <el-radio-button label="Class"></el-radio-button>
          <el-radio-button label="Provider"></el-radio-button>
        </el-radio-group>
        <span class="label">and</span>
        <el-radio-group v-model="graphSection.timeScale" size="small" @change="getSummary">
          <el-radio-button label="Month"></el-radio-button>
          <el-radio-button label="Quarter"></el-radio-button>
          <el-radio-button label="Year"></el-radio-button>
        </el-radio-group>
      </template>
      <div style="margin: 10px">
      <div class="chart-title">Medication Usage</div>
        <stacked-column-chart :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxis" :yAxisPlotLines="graphSection.plotLines" :usePerColumnLabels="true" :configuration="graphSection.customizations"></stacked-column-chart>
      </div>
    </page-section>

    <page-section title="Summary" v-loading="summarySection.loading">
      <template slot="title">
        <el-button size="small" @click="exportSummaryToExcel">Export Data</el-button>
      </template>
      <el-table :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
        <template v-for="colKey in summarySection.columns">
          <el-table-column :key="colKey" :label="colKey">
            <template scope="scope">
              {{ scope.row[colKey] ? scope.row[colKey].count : "--" }}
            </template>
          </el-table-column>
        </template>
        <el-table-column label="Total">
          <template scope="scope">
            {{ scope.row.summationColumn }}
          </template>
        </el-table-column>
      </el-table>
    </page-section>

    <page-section :title="`Analysis Details (${detailsSection.pagination.total})`" v-loading="!detailsTableReady">
      <template slot="title">
        <el-button size="small" @click="exportObservationsToExcel()">Export Data</el-button>
        <el-pagination
          @size-change="
            (size) => {
              detailsSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              detailsSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="detailsSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="detailsSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="detailsSection.data">
        <el-table-column label="Name" width="150" fixed>
          <template scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
            ({{ scope.row.client.staffId || scope.row.client.patientId }})
          </template>
        </el-table-column>
        <el-table-column prop="medication" label="Medication" width="200"> </el-table-column>
        <el-table-column prop="classification" label="Class" width="200"> </el-table-column>
        <el-table-column prop="prophylactic" label="Prophylactic" width="100"> </el-table-column>
        <el-table-column prop="startDateFormatted" label="Start Date" width="100"> </el-table-column>
        <el-table-column prop="endDateFormatted" label="End Date" width="100"> </el-table-column>
        <el-table-column prop="daysOfTherapy" label="Rx DOT" width="75"> </el-table-column>
        <el-table-column prop="dosage" label="Dosage" width="75"> </el-table-column>
        <el-table-column prop="frequency" label="Frequency" width="100"> </el-table-column>
        <el-table-column prop="route" label="Route" width="100"> </el-table-column>
        <el-table-column prop="provider" label="Provider" min-width="200"> </el-table-column>
      </el-table>
    </page-section>

    <progress-overlay v-if="detailsSection.exportingObservations" :progress="detailsSection.observationExportProgress" v-on:progress-cancelled="cancelObservationExport" title="Download In Progress"></progress-overlay>

    <el-dialog title="Error, please try again" v-model="detailsSection.loadingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error loading Observations. Please click refresh to try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            getDetails();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="Error, please try again" v-model="detailsSection.exportingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error exporting Observations. Please try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            exportObservationsToExcel();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../../../auth";
import moment from "moment";
import XLSX from "xlsx";
import util from "../../../util";
import ProgressOverlay from "../../../components/ProgressBarOverlay";
import FiltersSection from "../../Shared/FiltersSection";
import PageSection from "../../Shared/PageSection";
import StackedColumnChart from "../../Shared/StackedColumnChart";

export default {
  name: "medication-usage-page",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "stacked-column-chart": StackedColumnChart,
  },
  watch: {
    "detailsSection.pagination.size"() {
      this.getDetails();
    },
    "detailsSection.pagination.page"() {
      this.getDetails();
    },
  },
  computed: {
    detailsTableReady() {
      return this.global.medications !== null && !this.detailsSection.loading;
    },
  },
  data() {
    return {
      global: {
        settings: {},
        filters: {},
        defaultDateStart: moment().subtract(90, "days").toDate(),
        sites: [],
        audits: [],
        momentGroups: [],
        criteria: {},
        facilities: [],
        showMoreFilterModal: false,
        additionalFilters: ["clientStatus", "infectionSites", "resolutions", "primaryOrganisms", "caseStatus"],
        medications: null,
        providers: {},
      },
      summarySection: {
        loading: true,
        data: [],
        showTrueCompliance: false,
        exportModels: {},
      },
      detailsSection: {
        loading: true,
        loadingDependencies: true,
        editFormValues: {},
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        exportingObservations: false,
        observationExportProgress: 0,
        observationTitle: "",
        loadingErrorDialog: false,
        exportingErrorDialog: false,
        data: [],
      },
      graphSection: {
        loading: true,
        groupOptions: {
          Medication: "medication",
          Class: "classification",
          Provider: "provider",
        },
        groupBy: "Medication",
        timeScale: "Month",
        series: [],
        title: "",
        yAxis: "Prescriptions",
        xAxis: [],
        plotLines: [],
        customizations: {
          legend: {
            enabled: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                format: "{y}",
              },
            },
            column: {
              stacking: undefined,
            },
          },
        },
      },
    };
  },
  methods: {
    getFilterKeys() {
      return ["medicationDate", "medications", "providers", "clientType"];
    },
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "infectionSites":
                v = v[0] === 0 ? ["all"] : [v[0].type];
                break;
              case "providers":
                v = v.map((id) => `${this.global.providers[id].firstName} ${this.global.providers[id].lastName} (${this.global.providers[id].staffId})`);
                break;
              case "resolutions":
                k = "outcomes";
                v = v[0] === 0 ? ["all"] : [v[0]];
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      this.global.filters.prescriptionStartDateFrom = moment(filterValues.medicationDate[0]).startOf("day").format();
      this.global.filters.prescriptionStartDateTo = moment(filterValues.medicationDate[1]).endOf("day").format();
      if (rawFilters.infectionSites && rawFilters.infectionSites[1]) this.global.filters.infectionSiteSubTypes = [rawFilters.infectionSites[1]];
      if (rawFilters.resolutions && rawFilters.resolutions[1]) this.global.filters.outcomeSubTypes = [rawFilters.resolutions[1]];
      delete this.global.filters.medicationDate;
      this.refreshAllData();
    },
    dateToTimeScale(dateObj) {
      switch (this.graphSection.timeScale) {
        case "Week":
          return `W${moment(dateObj).format("WW-YYYY")}`;
        case "Month":
          return `${moment(dateObj).format("YYYY-MM")}`;
        case "Quarter":
          return `Q${moment(dateObj).format("Q-YYYY")}`;
        default:
          return `${moment(dateObj).format("YYYY")}`;
      }
    },
    generateGraphData(groupedTotals, timePeriods) {
      let orderedTotals = [...groupedTotals.entries()];
      this.graphSection.xAxis = timePeriods;

      this.graphSection.title = ``;
      this.graphSection.series = orderedTotals.flatMap(([name, periods]) => {
        return {
          name,
          data: timePeriods.map((period) => periods[period].count),
          dataLabels: {
            enabled: true,
          },
        };
      });
    },
    generateSummaryTableData(groupedTotals, timePeriods) {
      this.summarySection.data = [];
      this.summarySection.columns = [...timePeriods];
      const orderedTotals = [...groupedTotals.entries()];

      const summationRow = { label: "Total", summationColumn: 0 };
      orderedTotals.forEach(([groupLabel, value]) => {
        const row = { label: groupLabel, ...value };
        this.summarySection.columns.forEach((colKey) => {
          row.summationColumn = row.summationColumn || 0;
          row.summationColumn += row[colKey].count;

          // sum up a column's count value
          summationRow[colKey] = summationRow[colKey] || { count: 0 };
          summationRow[colKey].count += row[colKey].count;
          summationRow.summationColumn += row[colKey].count;
        });
        this.summarySection.data.push(row);
      });
      this.summarySection.data.push(summationRow);
    },
    generateDetailsTableData(record) {
      let model = { ...record };
      model.startDateFormatted = moment(record.startDate).format(this.$configStore.dateFormat());
      model.endDateFormatted = moment(record.endDate).format(this.$configStore.dateFormat());
      // model.facility = this.global.facilities.filter(s => s.id === Number(record.facId))[0].displayText
      model.medication = this.global.medications[model.medicationId]?.displayText;
      model.classification = this.global.medications[model.medicationId]?.classification;
      model.prophylactic = model.prophylactic ? "Yes" : "No";

      const { firstName, lastName, middleName } = model.client;
      model.name = [firstName, middleName, lastName].filter(Boolean).join(" ");
      return model;
    },
    createSummary(summary) {
      const timeScale = this.graphSection.timeScale;
      const formatTime = {
        Week: (record) => `W${record.dategroup}-${record.year}`,
        Month: (record) => `${record.year}-${`${record.dategroup}`.padStart(2, "0")}`,
        Quarter: (record) => `Q${record.dategroup}-${record.year}`,
        Year: (record) => `${record.year}`,
      };

      const allTimePeriods = new Map();
      const groupKey = this.graphSection.groupOptions[this.graphSection.groupBy];

      const convertAndGroup = (record) => {
        const row = {
          time: formatTime[timeScale](record),
          group: record[groupKey],
          count: record.total,
          data: record,
        };
        allTimePeriods.set(row.time, { count: 0 });
        return row;
      };
      const orderedCounts = summary.map(convertAndGroup);
      const getSummaryDefaults = () => JSON.parse(JSON.stringify(Object.fromEntries(allTimePeriods)));

      let groupedTotals = new Map();

      orderedCounts.forEach((row) => {
        if (!groupedTotals.has(row.group)) {
          groupedTotals.set(row.group, getSummaryDefaults());
        }
        let values = groupedTotals.get(row.group);
        values[row.time] = values[row.time] || { count: 0 };
        values[row.time].count += row.count;
        groupedTotals.set(row.group, values);
      });
      return [groupedTotals, [...new Set(orderedCounts.map((c) => c.time))]];
    },
    exportSummaryToExcel() {
      this.summarySection.loading = true;
      const columns = [this.graphSection.groupBy, ...this.summarySection.columns, "Total"];
      const data = this.summarySection.data.map((row) => {
        return [row.label, ...this.summarySection.columns.map((period) => row[period].count), row.summationColumn];
      });
      this.createExcelFile("report.xlsx", columns, data);
      this.summarySection.loading = false;
    },
    async exportObservationsToExcel() {
      this.detailsSection.exportingObservations = true;
      this.detailsSection.observationExportProgress = 0;
      let pageProgress = 0;
      let hasMorePages = true;
      const size = 1000;
      const columns = ["Name", "Staff ID", "Patient ID", "Medication", "Class", "Prophylactic", "Start Date", "End Date", "Prescription DOT", "Dosage", "Frequency", "Route", "Provider"];

      const convertDataToColumns = (json) => {
        const data = this.generateDetailsTableData(json);
        const { name, medication, classification, prophylactic, startDateFormatted, endDateFormatted, daysOfTherapy, dosage, frequency, route, provider } = data;
        return [name, data.client.staffId || null, data.client.patientId || null, medication, classification, prophylactic, startDateFormatted, endDateFormatted, daysOfTherapy, dosage, frequency, route, provider];
      };

      const getPageOfData = async (page) => {
        return await this.$http
          .post(`${window.CONFIG.treatement_api}/prescriptions/search`, {
            ...this.global.filters,
            page,
            size,
          })
          .then((response) => response.body);
      };
      let data = [];
      while (hasMorePages) {
        this.detailsSection.exportingErrorDialog = false;
        if (!this.detailsSection.exportingObservations) {
          this.cancelObservationExport();
          break;
        }
        const json = await getPageOfData(pageProgress + 1).catch((err) => {
          if (err.status === 0) {
            this.cancelObservationExport();
            this.detailsSection.exportingErrorDialog = true;
          }
        });
        this.detailsSection.observationExportProgress = Math.round(((pageProgress + 1) / json.totalPages) * 100);
        if (json.totalPages > pageProgress + 1) {
          pageProgress++;
        } else {
          hasMorePages = false;
        }
        data.push(json.content);
      }
      if (!this.detailsSection.exportingObservations) {
        return;
      }
      data = data.flat().map(convertDataToColumns);
      this.createExcelFile("report.xlsx", columns, data);
      this.detailsSection.exportingObservations = false;
      this.detailsSection.exportingErrorDialog = false;
    },
    createExcelFile: function (fileName, columns, data) {
      data.unshift(columns);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelObservationExport() {
      this.detailsSection.exportingObservations = false;
      this.detailsSection.observationExportProgress = 0;
    },
    async getDetails() {
      this.detailsSection.loading = true;
      const json = await this.$http
        .post(`${window.CONFIG.treatement_api}/prescriptions/search`, {
          ...this.global.filters,
          page: this.detailsSection.pagination.page - 1,
          size: this.detailsSection.pagination.size,
        })
        .then((response) => response.body)
        .catch((err) => {
          if (err.status === 0) {
            this.detailsSection.loadingErrorDialog = true;
          }
          this.detailsSection.loading = false;
          return {
            page: 0,
            content: [],
            totalElements: 0,
          };
        });
      this.detailsSection.pagination.total = json.totalElements;
      this.detailsSection.data = json.content.map(this.generateDetailsTableData);
      this.detailsSection.loading = false;
    },
    async getSummary() {
      this.graphSection.loading = true;
      this.summarySection.loading = true;

      const summary = await this.$http
        .post(`${window.CONFIG.treatement_api}/prescriptions/medications/summary`, {
          ...this.global.filters,
          groupBy: this.graphSection.groupOptions[this.graphSection.groupBy],
          timeScale: this.graphSection.timeScale.toLowerCase(),
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        .then((response) => response.body)
        .catch(() => {
          this.summarySection.loading = false;
          this.graphSection.loading = false;
          return [];
        });
      const [groups, periods] = this.createSummary(summary);
      this.summarySection.exportModels = { groups, periods };
      this.generateSummaryTableData(groups, periods);
      this.generateGraphData(groups, periods);
      this.graphSection.loading = false;
      this.summarySection.loading = false;
    },
    async refreshAllData() {
      if (this.detailsTableReady) {
        this.getDetails();
      }
      this.getSummary();
    },
    isValidOutcome(data) {
      const criteria = this.global.criteria;
      if (criteria[data.outcome] !== undefined && data.seconds >= criteria[data.outcome]) {
        return true;
      } else {
        return false;
      }
    },
    normalizeOutcome(data) {
      if (data.outcome === "Missed") {
        return "missed";
      }
      if (this.isValidOutcome(data)) {
        return "complete";
      }
      return "incomplete";
    },
    isHHAdmin: function () {
      return auth.userInfo().roles.indexOf("ROLE_HH_ADMIN") >= 0;
    },
    isWebObserver: function () {
      return auth.userInfo().roles.indexOf("ROLE_HH_WEB_OBSERVER") >= 0;
    },
    allowedToEdit: function () {
      return this.isHHAdmin() || this.isWebObserver();
    },
    renderCompliance(record) {
      const perc = this.summarySection.showTrueCompliance ? record.percentTrueCompliance : record.percent;
      const numerator = this.summarySection.showTrueCompliance ? record.complete : record.complete + record.incomplete;
      const denominator = record.total;
      if (denominator === 0) {
        return "--";
      }
      return `${perc}% <span class="bracket">(${numerator}/${denominator})</span>`;
    },
  },
  async created() {
    console.clear();
    this.global.facilities = this.$configStore.data.sites;
    this.$http
      .get(`${window.CONFIG.api}/clients/search`, {
        params: {
          providerType: "Yes",
          status: "C",
          clientType: "Staff",
        },
      })
      .then((resp) => resp.json())
      .then((providers) => {
        this.global.providers = util.arrayToObj(providers, "id");
      });
    await this.$http
      .get(`${window.CONFIG.treatement_api}/config`)
      .then((resp) => resp.json())
      .then((json) => [...json.medications, ...json.archivedMedications])
      .then((meds) => {
        this.global.medications = util.arrayToObj(meds, "id");
        this.getDetails();
      });
  },
};
</script>

<style>
.medication-usage {
  --status-missed: #ff5050;
  --status-complete: #00d560;
  --status-incomplete: #ffcc00;
}
.medication-usage .outcome {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: var(--status-missed);
}
.medication-usage .complete {
  background-color: var(--status-complete);
}
.medication-usage .incomplete {
  background-color: var(--status-incomplete);
}
.medication-usage .el-dialog .el-input {
  /* width: 300px; */
}
.medication-usage .complianceToggle {
  margin-right: 10px;
}
.medication-usage .comments {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.medication-usage .bracket {
  color: #a7a7a7;
}
.medication-usage .chart-title {
  text-align: center;
  margin-top: 5px;
  font-size: 18px !important;
  font-weight: bold;
}
</style>
