<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';

ExportingHighcharts(Highcharts);

export default {

components: {
    highcharts: Chart
  },
  props: {
    series: Array,
    xAxisCategories: Array,
  },
  watch: {
    series(val) {
      this.chartOptions.series = val;
    },
    xAxisCategories(val) {
      this.chartOptions.xAxis.categories = val;
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'line',
          height: 100,
          width: 300,
          backgroundColor: null
        },
        exporting: {
            enabled: false
        },
        credits: {
          enabled: false
        },
        title: null,
        xAxis: {
          categories: this.xAxisCategories,
          visible: false
        },
        yAxis: {
          visible: false
        },
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 1,
            shadow: false,
            fillOpacity: 0.25,
            marker: {
              enabled: false
            },
          }
        },
        series: this.series
      }
    };
  }
};
</script>