<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import { Chart } from 'highcharts-vue';

ExportingHighcharts(Highcharts);
export default {
  components: {
    highcharts: Chart,
  },
  props: {
    title: String,
    series: Array,
    xAxisTitle: String,
    xAxisCategories: Array,
    yAxisTitle: String,
  },
  watch: {
    title(val) {
      this.chartOptions.title.text = val;
    },
    series(val) {
      this.chartOptions.series = val;
    },
    yAxisTitle(val) {
      this.chartOptions.yAxis.title.text = val;
    },
    xAxisCategories(val) {
      this.chartOptions.xAxis.categories = val;
    },
  },
  data() {
    const colors = Highcharts.getOptions().colors;
    return {
      chartOptions: {
        chart: {
          type: 'line',
          zoomType: 'x'
        },
        title: {
          text: this.title
        },
        xAxis: {
          title: {
            text: this.xAxisTitle
          },
          categories: this.xAxisCategories
        },
        yAxis: {
          max: 100,
          title: {
            text: this.yAxisTitle
          }
        },
        legend: {
          verticalAlign: 'top',
        },
        credits: {
          enabled: false
        },
        tooltip: {
          shared: true,
          valueSuffix: '%'
        },
        series: this.series.map((value, index) => {
          return {
            name: value.name,
            data: value.data,
            color: colors[index],
          }
        }),
      }
    };
  }
};
</script>