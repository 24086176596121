<template>
  <div class="treatments-tab">
    <table-heading :title="'Linked Treatments'">
      <template slot="right-alignment">
        <el-button type="info" @click="lineListReport()">Antimicrobial Time Out Report</el-button>
        <el-pagination
          @size-change="
            size => {
              linked.pagination.size = size;
            }
          "
          @current-change="
            page => {
              linked.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="linked.pagination.size"
          :page-count="linked.totalPages"
          layout="sizes, prev, pager, next"
        >
        </el-pagination>
      </template>
    </table-heading>

    <el-table class="linked-table" :data="linked.list" v-loading="linked.loading">
      <el-table-column label="Medication" width="250">
        <template scope="scope">
          {{ getMedicineName(scope.row.medicationId) }}
        </template>
      </el-table-column>

      <el-table-column label="Prophylactic" width="100">
        <template scope="scope">
          {{ scope.row.prophylactic ? "Yes" : "No" }}
        </template>
      </el-table-column>

      <el-table-column label="Start Date" width="100">
        <template scope="scope">
          {{ formatDate(scope.row.startDate) }}
        </template>
      </el-table-column>

      <el-table-column label="End Date" width="100">
        <template scope="scope">
          {{ formatDate(scope.row.endDate) }}
        </template>
      </el-table-column>

      <el-table-column label="Dosage" width="75">
        <template scope="scope">
          {{ scope.row.dosage }}
        </template>
      </el-table-column>

      <el-table-column label="Freq." width="75">
        <template scope="scope">
          {{ scope.row.frequency }}
        </template>
      </el-table-column>

      <el-table-column label="Route" width="120">
        <template scope="scope">
          {{ scope.row.route }}
        </template>
      </el-table-column>

      <el-table-column label="Provider" width="200">
        <template scope="scope">
          {{ scope.row.provider }}
        </template>
      </el-table-column>

      <el-table-column label="Reviewed?" width="85">
        <template scope="scope">
          {{ scope.row.reviewed ? "Yes" : "No" }}
        </template>
      </el-table-column>

      <el-table-column label="Reviewer" width="150">
        <template scope="scope">
          {{ scope.row.reviewedBy }}
        </template>
      </el-table-column>

      <el-table-column label="Reviewed On" width="100">
        <template scope="scope">
          {{ formatDate(scope.row.reviewed) }}
        </template>
      </el-table-column>

      <el-table-column label="Notes" min-width="400">
        <template scope="scope">
          {{ scope.row.notes }}
        </template>
      </el-table-column>

      <el-table-column v-if="!isReadOnly" label="" width="100" align="right" fixed="right">
        <template scope="scope">
          <el-button-group>
            <el-button type="info" @click="generateNote(scope.row)" size="small">
              <Icon :iconKey="'generateNote'" :description="'Generate Note'" />
            </el-button>
            <el-button @click="unlinkTreatment(scope.row)" size="small">
              <Icon :iconKey="'unlink'" :description="'Unlink'" />
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <table-heading :title="'Treatment History'">
      <template slot="right-alignment">
        <el-button v-if="!isReadOnly" @click="createTreatment()">+ New Treatment</el-button>
        <el-pagination
          @size-change="
            size => {
              history.pagination.size = size;
            }
          "
          @current-change="
            page => {
              history.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="history.pagination.size"
          :page-count="history.totalPages"
          layout="sizes, prev, pager, next"
        >
        </el-pagination>
      </template>
    </table-heading>

    <el-table class="history-table" :data="history.list" v-loading="history.loading">
      <el-table-column label="Medication" width="250">
        <template scope="scope">
          {{ getMedicineName(scope.row.medicationId) }}
        </template>
      </el-table-column>

      <el-table-column label="Prophylactic" width="100">
        <template scope="scope">
          {{ scope.row.prophylactic ? "Yes" : "No" }}
        </template>
      </el-table-column>

      <el-table-column label="Start Date" width="100">
        <template scope="scope">
          {{ formatDate(scope.row.startDate) }}
        </template>
      </el-table-column>

      <el-table-column label="End Date" width="100">
        <template scope="scope">
          {{ formatDate(scope.row.endDate) }}
        </template>
      </el-table-column>

      <el-table-column label="Dosage" width="75">
        <template scope="scope">
          {{ scope.row.dosage }}
        </template>
      </el-table-column>

      <el-table-column label="Freq." width="75">
        <template scope="scope">
          {{ scope.row.frequency }}
        </template>
      </el-table-column>

      <el-table-column label="Route" width="120">
        <template scope="scope">
          {{ scope.row.route }}
        </template>
      </el-table-column>

      <el-table-column label="Provider" width="200">
        <template scope="scope">
          {{ scope.row.provider }}
        </template>
      </el-table-column>

      <el-table-column label="Reviewed?" width="85">
        <template scope="scope">
          {{ scope.row.reviewed ? "Yes" : "No" }}
        </template>
      </el-table-column>

      <el-table-column label="Reviewer" width="150">
        <template scope="scope">
          {{ scope.row.reviewedBy }}
        </template>
      </el-table-column>

      <el-table-column label="Reviewed On" width="100">
        <template scope="scope">
          {{ formatDate(scope.row.reviewed) }}
        </template>
      </el-table-column>

      <el-table-column label="Notes" min-width="400">
        <template scope="scope">
          {{ scope.row.notes }}
        </template>
      </el-table-column>

      <el-table-column v-if="!isReadOnly" label="" width="160" align="right" fixed="right">
        <template scope="scope">
          <el-button-group>
            <el-button type="info" @click="generateNote(scope.row)" size="small">
              <Icon :iconKey="'generateNote'" :description="'Generate Note'" />
            </el-button>

            <el-button :disabled="!!scope.row.symptomDetailId" @click="linkTreatment(scope.row)" size="small">
              <Icon :iconKey="'link'" :description="'Link'" />
            </el-button>

            <el-button @click="editTreatment(scope.row)" size="small">
              <Icon :iconKey="'edit'" :description="'Edit'" />
            </el-button>

            <el-button @click="deleteTreatment(scope.row)" size="small">
              <Icon :iconKey="'delete'" :description="'Delete'" />
            </el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>

    <treatment-editor v-if="showEditor" v-on:editor-close="cancelEditor" v-on:editor-save="editorComplete" :treatmentData="selectedTreatment" :caseData="caseData" />

    <div ref="noteDialogContainer">
      <el-dialog :visible="generatedNoteVisibility" title="Generated Note" :close-on-click-modal="false" :close-on-press-escape="false">
        <template>
          <textarea v-model="generatedNoteString" style="font: 12px/1.5em 'Menlo', 'Consolas', monospace; height:300px; width: 100%; overflow:auto;"> </textarea>
          <el-button @click="copyNote()">Copy to Clipboard</el-button>
        </template>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="
              () => {
                this.generatedNoteVisibility = false;
              }
            "
            >Close</el-button
          >
        </span>
      </el-dialog>
    </div>
    <ProgressOverlay v-if="excelExportResultsVisible" :progress="excelExportResultsProgress" v-on:progress-cancelled="cancelExcelExport" title="Download In Progress"> </ProgressOverlay>
  </div>
</template>

<script>
import auth from "../../auth";
import moment from "moment";
import { initLineListReportGenerator } from "../../services/reportClient";

import CaseDetailHeading from "../Shared/CaseDetailTableHeading";
import TreatmentEditor from "./Editor.vue";
import Icon from "../Shared/Icon";
import ProgressOverlay from "../../components/ProgressBarOverlay";

// const snapshot = obj => {
//   console.clear();
//   console.log(JSON.stringify(obj, undefined, "  "));
// };

export default {
  name: "treatments",
  props: ["caseData"],
  components: {
    "table-heading": CaseDetailHeading,
    "treatment-editor": TreatmentEditor,
    Icon,
    ProgressOverlay
  },
  watch: {
    "linked.pagination.page": function() {
      this.updateLinked();
    },
    "linked.pagination.size": function() {
      this.updateLinked();
    },
    "history.pagination.page": function() {
      this.updateHistory();
    },
    "history.pagination.size": function() {
      this.updateHistory();
    }
  },
  data() {
    return {
      linked: {
        list: [],
        loading: true,
        pagination: {
          page: 1,
          size: 5
        },
        totalPages: 0
      },
      history: {
        list: [],
        loading: true,
        pagination: {
          page: 1,
          size: 5
        },
        totalPages: 0
      },
      generatedNoteString: "",
      generatedNoteVisibility: false,
      isReadOnly: false,
      showEditor: false,
      selectedTreatment: null,
      linkedTreatments: [],
      treatmentsHistory: [],
      medicationsLookup: {},
      excelExportResultsVisible: false,
      excelExportResultsProgress: 0,
      cancelExcelExportProgress: false
    };
  },
  methods: {
    unlinkTreatment(record) {
      this.toggleTreatmentLinkage(record, null);
    },
    linkTreatment(record) {
      this.toggleTreatmentLinkage(record, this.caseData.id);
    },
    async toggleTreatmentLinkage(record, idOrNull) {
      const data = JSON.stringify({ ...record, ...{ symptomDetailId: idOrNull } });
      await this.$http.put(`${window.CONFIG.treatement_api}/prescriptions/${record.id}`, data);
      this.updateTreatments();
    },
    createTreatment() {
      this.showEditor = true;
    },
    editTreatment(record) {
      this.selectedTreatment = record;
      this.showEditor = true;
    },
    async deleteTreatment(record) {
      await this.$http.delete(`${window.CONFIG.treatement_api}/prescriptions/${record.id}`);
      this.updateTreatments();
    },
    cancelEditor() {
      this.showEditor = false;
      this.selectedTreatment = null;
    },
    editorComplete() {
      this.showEditor = false;
      this.selectedTreatment = null;
      this.updateTreatments();
    },
    formatDate(timestamp) {
      return timestamp ? moment(timestamp).format(this.$configStore.dateFormat()) : "";
    },
    getMedicineName(id) {
      return this.medicationsLookup[id]?.displayText || "";
    },
    getMedicineClass(id) {
      return this.medicationsLookup[id]?.classification || "";
    },
    arrayToObj(array, key) {
      return Object.fromEntries([...array.entries()].map(([, obj]) => [obj[key], obj]));
    },
    async updateLinked() {
      const symptomDetailId = this.caseData.id;
      return this.$http
        .get(`${window.CONFIG.treatement_api}/prescriptions`, {
          params: {
            clientId: this.caseData.client.id,
            ...this.linked.pagination,
            symptomDetailId
          }
        })
        .then(resp => resp.json())
        .then(json => {
          this.linked.list = json.content;
          this.linked.totalPages = json.totalPages;
        })
        .then(() => (this.linked.loading = false));
    },
    async updateHistory() {
      this.history.loading = true;
      return this.$http
        .get(`${window.CONFIG.treatement_api}/prescriptions`, {
          params: {
            clientId: this.caseData.client.id,
            ...this.history.pagination
          }
        })
        .then(resp => resp.json())
        .then(json => {
          this.history.list = json.content;
          this.history.totalPages = json.totalPages;
        })
        .then(() => (this.history.loading = false));
    },
    async updateTreatments() {
      await this.updateLinked();
      await this.updateHistory();
    },
    generateNote(data) {
      const { medicationId, client, dosage, frequency, startDate, endDate } = data;
      this.generatedNoteString = `${client.firstName} ${client.lastName} is being treated with ${this.getMedicineName(medicationId)} ${dosage} ${frequency}. Treatment started on ${this.formatDate(startDate)}, ending on ${this.formatDate(endDate)}.`;
      this.generatedNoteVisibility = true;
      if (this.$refs.noteDialogContainer) {
        document.body.append(this.$refs.noteDialogContainer);
      }
    },
    copyNote() {
      navigator.clipboard.writeText(this.generatedNoteString).then(
        () => {
          this.$message("Note successfully copied!");
        },
        () => {
          this.$message.error("Note could not be copied");
        }
      );
    },
    getFacilityNameForResident(id) {
      return (this.$configStore.data.sites || []).filter(s => s.id === id)[0]?.displayText;
    },
    getFacilityNamesForStaff(ids) {
      return (this.$configStore.data.sites || []).filter(s => ids.includes(s.id)).map(s => s.displayText);
    },
    isStaff: function(client) {
      if (client) {
        return client.clientType === "Staff";
      } else {
        return false;
      }
    },
    cancelExcelExport() {
      this.excelExportResultsVisible = false;
      this.excelExportResultsProgress = 0;
      this.cancelExcelExportProgress = true;
    },
    lineListReport: async function() {
      this.excelExportResultsVisible = true;
      this.excelExportResultsProgress = 0;
      this.cancelExcelExportProgress = false;
      const { symptoms, id: symptomDetailId, infectionSite } = this.caseData;
      const meds = this.medicationsLookup;
      const fetchDataForColumns = async page => {
        const response = await this.$http
          .get(`${window.CONFIG.treatement_api}/prescriptions`, {
            params: {
              clientId: this.caseData.client.id,
              size: 30,
              page,
              symptomDetailId
            }
          })
          .then(resp => resp.json());

        for (const prescription of response.content) {
          prescription.labs = await this.$http.get(`${window.CONFIG.api}/symptomDetail/${prescription.symptomDetailId}/labs`).then(resp => resp.json());
        }

        return response;
      };
      const formatDate = timestamp => moment(timestamp).format(this.$configStore.dateFormat());
      const formatFac = _ => (this.isStaff(_.client) ? this.getFacilityNamesForStaff(_.client.facilities).join(", ") : this.getFacilityNameForResident(_.facId));

      const convertDataToColumns = _ => {
        const c = _.client;
        const { startDate, endDate, frequency, dosage, medicationId, daysOfTherapy, route, provider, labs } = _;
        return {
          reportDate: formatDate(Date.now()),
          facility: formatFac(_),
          name: `${c.firstName} ${c.lastName}`,
          startDate: formatDate(startDate),
          endDate: formatDate(endDate),
          frequency,
          dosage,
          medicationId,
          medication: meds[medicationId],
          route,
          provider,
          daysOfTherapy,
          symptoms,
          infectionSite,
          labs: labs.map(l => {
            return {
              ...l,
              cultureDate: formatDate(l.cultureDate),
              collectionDate: formatDate(l.collectionDate)
            };
          })
        };
      };

      let data = [];
      let currentPage = 1;
      let hasMore = true;
      while (hasMore) {
        if (this.cancelExcelExportProgress) {
          break;
        }
        const json = await fetchDataForColumns(currentPage);
        data = [...data, ...json.content.map(convertDataToColumns)];
        hasMore = json.totalPages !== currentPage;
        currentPage++;
      }
      this.excelExportResultsVisible = false;
      data = data.sort((a, b) => b.onSetDate - a.onSetDate);
      if (!this.cancelExcelExportProgress) {
        const { antimicrobialTimeout } = this.$configStore.getLineListReports();
        initLineListReportGenerator(antimicrobialTimeout, data);
      }
    }
  },
  async mounted() {
    this.isReadOnly = !(auth.userInfo().roles.includes("TRT_UPDATE") && auth.userInfo().roles.includes("TRT_CREATE") && auth.userInfo().roles.includes("TRT_DELETE"));
    const config = await this.$http.get(`${window.CONFIG.treatement_api}/config`).then(resp => resp.json());
    this.medicationsLookup = this.arrayToObj(config.medications, "id");
    this.sites = this.$configStore.data.sites || [];
    await this.updateTreatments();
  }
};
</script>
<style scoped>
.linked-table {
  margin-bottom: 30px;
}
</style>
