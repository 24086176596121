<template>
  <div class="infection-rates">
    <filters-section :showLocation="true" :filterDisplayKeys="graphSection.filterKeys" :moreFilters="global.additionalFilters" :dateRangeStart="global.defaultDateStart" v-on:change="formatFilters"> </filters-section>

    <page-section title="Graph" v-loading="graphSection.loading">
      <template slot="title">
        <span class="label">Group by</span>
        <el-radio-group v-model="graphSection.groupBy" size="small" @change="getSummary">
          <el-radio-button label="Infection Site"></el-radio-button>
          <el-tooltip :disabled="!global.filters.unique">
            <div slot="content">Group by Precaution is disabled when Unique Count is On</div>
            <el-radio-button :disabled="global.filters.unique" label="Precaution"></el-radio-button>
          </el-tooltip>
          <el-radio-button label="Unit"></el-radio-button>
          <el-radio-button label="Facility"></el-radio-button>
        </el-radio-group>
        <span class="label">and</span>
        <el-radio-group v-model="graphSection.timeScale" size="small" @change="getSummary">
          <el-radio-button label="Week"></el-radio-button>
          <el-radio-button label="Month"></el-radio-button>
          <el-radio-button label="Quarter"></el-radio-button>
          <el-radio-button label="Year"></el-radio-button>
        </el-radio-group>
      </template>
      <div style="margin: 10px">
        <div class="chart-title">Isolation Days</div>
        <stacked-column-chart :title="graphSection.title" :series="graphSection.series" :xAxisCategories="graphSection.xAxis" :yAxisTitle="graphSection.yAxis" :yAxisPlotLines="graphSection.plotLines" :usePerColumnLabels="true" :configuration="graphSection.customizations"></stacked-column-chart>
      </div>
    </page-section>

    <page-section title="Summary" v-loading="summarySection.loading">
      <template slot="title">
        <el-button size="small" @click="exportSummaryToExcel">Export Data</el-button>
      </template>
      <el-table :data="summarySection.data">
        <el-table-column prop="label" :label="graphSection.groupBy"></el-table-column>
        <template v-for="colKey in summarySection.columns">
          <el-table-column :key="colKey" :label="colKey">
            <template scope="scope">
              {{ scope.row[colKey] ? scope.row[colKey].count : "--" }}
            </template>
          </el-table-column>
        </template>
        <el-table-column label="Total">
          <template scope="scope">
            {{ scope.row.summationColumn }}
          </template>
        </el-table-column>
      </el-table>
    </page-section>

    <page-section :title="`Analysis Details (${detailsSection.pagination.total})`" v-loading="!detailsTableReady">
      <template slot="title">
        <el-button size="small" @click="exportObservationsToExcel()">Export Data</el-button>
        <el-pagination
          @size-change="
            (size) => {
              detailsSection.pagination.size = size;
            }
          "
          @current-change="
            (page) => {
              detailsSection.pagination.page = page;
            }
          "
          :page-sizes="[5, 10, 20, 100]"
          :page-size="detailsSection.pagination.size"
          layout="sizes, prev, pager, next"
          :total="detailsSection.pagination.total"
        >
        </el-pagination>
      </template>
      <el-table :data="detailsSection.data">
        <el-table-column label="Name" width="250" fixed>
          <template scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
            ({{ scope.row.client.staffId || scope.row.patientIdFormatted }})
          </template>
        </el-table-column>
        <el-table-column prop="facility" label="Facility" width="200" v-if="$configStore.data.sites.length > 1"> </el-table-column>
        <el-table-column prop="unit" label="Unit" width="175"> </el-table-column>
        <el-table-column prop="infectionSite" label="Infection Site" width="150"> </el-table-column>
        <el-table-column label="Precaution" width="150">
          <template slot-scope="scope">
            <el-tag :type="precautionIsActive(scope.row) ? 'danger' : 'gray'">{{ scope.row.precaution }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="startDateFormatted" label="Start Date" width="200"> </el-table-column>
        <el-table-column prop="endDateFormatted" label="End Date" width="200"> </el-table-column>
        <el-table-column prop="isolationDays" label="Isolation Days" min-width="200"> </el-table-column>
        <el-table-column fixed="right" label="Actions" prop="active" width="120">
          <template scope="scope">
            <el-button :disabled="!canModify" :plain="true" size="mini" @click="editPrecaution(scope.row)">
              <Icon :iconKey="'edit'" :description="'Edit Precaution'" />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </page-section>

    <progress-overlay v-if="detailsSection.exportingObservations" :progress="detailsSection.observationExportProgress" v-on:progress-cancelled="cancelObservationExport" title="Download In Progress"></progress-overlay>

    <el-dialog title="Error, please try again" v-model="detailsSection.loadingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error loading Observations. Please click refresh to try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            getDetails();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="Error, please try again" v-model="detailsSection.exportingErrorDialog" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      There was an error exporting Observations. Please try again.
      <div style="display: flex; justify-content: center; margin-top: 25px">
        <el-button
          @click="
            detailsSection.loadingErrorDialog = false;
            exportObservationsToExcel();
          "
          >Refresh</el-button
        >
      </div>
    </el-dialog>

    <PrecautionEditor v-if="editor.visible" v-on:editor-save="hideEditPrecaution" v-on:editor-close="hideEditPrecaution" :precautions="editor.precautions" :precautionDetail="editor.data"></PrecautionEditor>
  </div>
</template>

<script>
import moment from "moment";
import XLSX from "xlsx";
import auth from "../../../auth";
import util from "../../../util";
import Icon from "../../Shared/Icon";
import ProgressOverlay from "../../../components/ProgressBarOverlay";
import FiltersSection from "../../Shared/FiltersSection";
import PageSection from "../../Shared/PageSection";
import StackedColumnChart from "../../Shared/StackedColumnChart";
import PrecautionEditor from "../../Precautions/Editor";

export default {
  name: "medication-usage-page",
  components: {
    "filters-section": FiltersSection,
    "page-section": PageSection,
    "progress-overlay": ProgressOverlay,
    "stacked-column-chart": StackedColumnChart,
    PrecautionEditor,
    Icon,
  },
  watch: {
    "detailsSection.pagination.size"() {
      this.getDetails();
    },
    "detailsSection.pagination.page"() {
      this.getDetails();
    },
    // groupBy === 'Precaution' and uniquePrecautionCount === true are incompatable options
    // ideally the filter should be disabled but due to limitations in how we can modify filters it is removed instead
    "graphSection.groupBy"(groupBy) {
      const index = this.graphSection.filterKeys.indexOf("uniquePrecautionCount");
      if (groupBy === "Precaution" && index !== -1) {
        // remove 'uniquePrecautionCount' filter
        this.graphSection.filterKeys.splice(index, 1);
      } else if (groupBy !== "Precaution" && index === -1) {
        // add 'uniquePrecautionCount' filter back to its original position
        const originalIndex = this.graphSection.uniquePrecautionIndex;
        if (originalIndex !== -1) {
          this.graphSection.filterKeys.splice(originalIndex, 0, "uniquePrecautionCount");
        }
      }
    },
  },
  computed: {
    detailsTableReady() {
      return this.global.precautions !== null && !this.detailsSection.loading;
    },
  },
  data() {
    return {
      canModify: false,
      global: {
        filters: {},
        defaultDateStart: moment().subtract(90, "days").toDate(),
        sites: [],
        facilities: [],
        precautions: null,
      },
      editor: {
        precautions: [],
        visible: false,
        data: null,
      },
      summarySection: {
        loading: true,
        data: [],
        exportModels: {},
      },
      detailsSection: {
        loading: true,
        pagination: {
          page: 1,
          size: 10,
          total: 0,
        },
        exportingObservations: false,
        observationExportProgress: 0,
        observationTitle: "",
        loadingErrorDialog: false,
        exportingErrorDialog: false,
        data: [],
      },
      graphSection: {
        loading: true,
        filterKeys: ["precautionDate", "basicInfectionSites", "precautions", "clientStatus", "uniquePrecautionCount", "activePrecautions"],
        uniquePrecautionIndex: -1,
        groupOptions: {
          Precaution: "precaution",
          "Infection Site": "infectionSite",
          Facility: "facility",
          Unit: "unit",
        },
        groupBy: "Facility",
        timeScale: "Month",
        series: [],
        title: "",
        yAxis: "Isolation Days",
        xAxis: [],
        plotLines: [],
        customizations: {
          legend: {
            enabled: true,
          },
          plotOptions: {
            series: {
              dataLabels: {
                format: "{y}",
              },
            },
            column: {
              stacking: undefined,
            },
          },
        },
      },
    };
  },
  methods: {
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
              case "uniquePrecautionCount":
                k = "unique";
                break;
              case "precautions":
                k = "precautionIds";
                break;
            }
            return [k, v];
          })
      );
      this.global.filters = { ...filterValues };
      if (filterValues.basicInfectionSites && !filterValues.basicInfectionSites.includes("All Infection Sites")) {
        this.global.filters.infectionSites = filterValues.basicInfectionSites;
      }
      delete this.global.filters.basicInfectionSites;
      if (this.global.filters.activePrecautions) {
        this.global.filters.active = true;
      }
      this.global.filters.dateRangeStart = moment(filterValues.precautionDate[0]).startOf("day").format();
      this.global.filters.dateRangeEnd = moment(filterValues.precautionDate[1]).endOf("day").format();

      delete this.global.filters.activePrecautions;
      delete this.global.filters.precautionDate;
      this.refreshAllData();
    },
    dateToTimeScale(dateObj) {
      switch (this.graphSection.timeScale) {
        case "Week":
          return `W${moment(dateObj).format("WW-YYYY")}`;
        case "Month":
          return `${moment(dateObj).format("YYYY-MM")}`;
        case "Quarter":
          return `Q${moment(dateObj).format("Q-YYYY")}`;
        default:
          return `${moment(dateObj).format("YYYY")}`;
      }
    },
    generateGraphData(groupedTotals, timePeriods) {
      let orderedTotals = [...groupedTotals.entries()];
      this.graphSection.xAxis = timePeriods;

      this.graphSection.title = ``;
      const infectionSiteColorPalette = {
        Respiratory: "blue",
        GI: "green",
        BSI: "red",
        UTI: "yellow",
        Skin: "pink",
        ARO: "black",
        AUTI: "orange",
        EENT: "purple",
        Other: "grey",
        Screening: "brown",
      };
      this.graphSection.series = orderedTotals.flatMap(([name, periods]) => {
        const color = this.graphSection.groupBy === "Infection Site" ? infectionSiteColorPalette[name] : undefined;
        return {
          name,
          color,
          data: timePeriods.map((period) => periods[period].count),
          dataLabels: {
            enabled: true,
          },
        };
      });
    },
    roundTwoDecimalPlaces(value) {
      return Math.round(value * 100) / 100;
    },
    generateSummaryTableData(groupedTotals, timePeriods) {
      this.summarySection.data = [];
      this.summarySection.columns = [...timePeriods];
      const orderedTotals = [...groupedTotals.entries()];

      const summationRow = { label: "Total", summationColumn: 0 };
      orderedTotals.forEach(([groupLabel, value]) => {
        const row = { label: groupLabel, ...value };
        this.summarySection.columns.forEach((colKey) => {
          row.summationColumn = row.summationColumn || 0;
          row.summationColumn += row[colKey].count;
          row.summationColumn = this.roundTwoDecimalPlaces(row.summationColumn);

          // sum up a column's count value
          summationRow[colKey] = summationRow[colKey] || { count: 0 };
          summationRow[colKey].count += row[colKey].count;
          summationRow[colKey].count = this.roundTwoDecimalPlaces(summationRow[colKey].count);
          summationRow.summationColumn += row[colKey].count;
          summationRow.summationColumn = this.roundTwoDecimalPlaces(summationRow.summationColumn);
        });
        this.summarySection.data.push(row);
      });
      this.summarySection.data.push(summationRow);
    },
    generateDetailsTableData(record) {
      let model = { ...record };
      model.infectionSite = record.infectionCase.infectionSite || "";
      model.startDateFormatted = record.startDate ? moment(record.startDate).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.endDateFormatted = record.endDate ? moment(record.endDate).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.createdDateFormatted = record.created ? moment(record.created).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.updatedDateFormatted = record.updated ? moment(record.updated).format(`${this.$configStore.dateFormat()} HH:mm`) : "";
      model.precaution = this.global.precautions[record.precautionId].displayText;
      const facility = this.global.facilities.find((s) => s.id === record.infectionCase.facId);
      model.facility = facility?.displayText;
      const unit = facility?.units?.find((unit) => unit.id === model.infectionCase.unitId);
      model.unit = unit?.displayText;
      const patientIdType = this.$configStore.patientIdType();
      model.patientIdFormatted = patientIdType ? record.client[patientIdType] : record.client.patientId;

      const { firstName, lastName } = model.client;
      model.name = [lastName, firstName].join(", ");
      return model;
    },
    createSummary(summary) {
      const timeScale = this.graphSection.timeScale;
      const formatTime = {
        Week: (record) => `W${record.dategroup}-${record.year}`,
        Month: (record) => `${record.year}-${`${record.dategroup}`.padStart(2, "0")}`,
        Quarter: (record) => `Q${record.dategroup}-${record.year}`,
        Year: (record) => `${record.year}`,
      };

      const allTimePeriods = new Map();
      const groupKey = this.graphSection.groupOptions[this.graphSection.groupBy];

      const convertAndGroup = (record) => {
        const row = {
          time: formatTime[timeScale](record),
          group: record[groupKey],
          count: record.total,
          data: record,
        };
        allTimePeriods.set(row.time, { count: 0 });
        return row;
      };
      const orderedCounts = summary.map(convertAndGroup);
      const getSummaryDefaults = () => JSON.parse(JSON.stringify(Object.fromEntries(allTimePeriods)));

      let groupedTotals = new Map();

      orderedCounts.forEach((row) => {
        if (!groupedTotals.has(row.group)) {
          groupedTotals.set(row.group, getSummaryDefaults());
        }
        let values = groupedTotals.get(row.group);
        values[row.time] = values[row.time] || { count: 0 };
        values[row.time].count += row.count;
        groupedTotals.set(row.group, values);
      });
      return [groupedTotals, [...new Set(orderedCounts.map((c) => c.time))]];
    },
    exportSummaryToExcel() {
      this.summarySection.loading = true;
      const columns = [this.graphSection.groupBy, ...this.summarySection.columns, "Total"];
      const data = this.summarySection.data.map((row) => {
        return [row.label, ...this.summarySection.columns.map((period) => row[period].count), row.summationColumn];
      });
      this.createExcelFile("report.xlsx", columns, data);
      this.summarySection.loading = false;
    },
    async exportObservationsToExcel() {
      this.detailsSection.exportingObservations = true;
      this.detailsSection.observationExportProgress = 0;
      let pageProgress = 0;
      let hasMorePages = true;
      const size = 1000;
      const columns = ["Name", "Staff ID", "Patient ID", "Infection Case ID", "Facility", "Unit", "Infection Site", "Precaution", "Start Date", "Start Time", "End Date", "End Time", "Isolation Days", "Created By", "Created Date", "Updated By", "Updated Date"];

      const convertDataToColumns = (json) => {
        const data = this.generateDetailsTableData(json);
        const { name, facility, patientIdFormatted, unit, infectionSite, precaution, startDate, endDate, isolationDays, createdDateFormatted, createdBy, updatedDateFormatted, updatedBy } = data;
        return [name, data.client.staffId || "", patientIdFormatted || "", data.infectionCase.id, facility, unit, infectionSite, precaution, moment(startDate).format(this.$configStore.dateFormat()), moment(startDate).format("HH:mm"), endDate ? moment(endDate).format(this.$configStore.dateFormat()) : "", endDate ? moment(endDate).format("HH:mm") : "", isolationDays, createdBy, createdDateFormatted, updatedBy, updatedDateFormatted];
      };

      const getPageOfData = async (page) => {
        return await this.$http
          .get(window.CONFIG.precaution_api, {
            params: {
              ...this.global.filters,
              includeInfectionCase: true,
              includeClient: true,
              page,
              size,
            },
          })
          .then((response) => response.body);
      };
      let data = [];
      while (hasMorePages) {
        this.detailsSection.exportingErrorDialog = false;
        if (!this.detailsSection.exportingObservations) {
          this.cancelObservationExport();
          break;
        }
        const json = await getPageOfData(pageProgress + 1).catch((err) => {
          if (err.status === 0) {
            this.cancelObservationExport();
            this.detailsSection.exportingErrorDialog = true;
          }
        });
        this.detailsSection.observationExportProgress = Math.round(((pageProgress + 1) / json.totalPages) * 100);
        if (json.totalPages > pageProgress + 1) {
          pageProgress++;
        } else {
          hasMorePages = false;
        }
        data.push(json.content);
      }
      if (!this.detailsSection.exportingObservations) {
        return;
      }
      data = data.flat().map(convertDataToColumns);
      this.createExcelFile("report.xlsx", columns, data);
      this.detailsSection.exportingObservations = false;
      this.detailsSection.exportingErrorDialog = false;
    },
    createExcelFile: function (fileName, columns, data) {
      data.unshift(columns);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    cancelObservationExport() {
      this.detailsSection.exportingObservations = false;
      this.detailsSection.observationExportProgress = 0;
    },
    precautionIsActive(row) {
      const { startDate, endDate } = row;
      // precaution is active when there's no end date
      if (!endDate) {
        return true;
      }
      // also active if between the start and end dates
      const now = new Date();
      return now >= new Date(startDate) && now <= new Date(endDate);
    },
    async getDetails() {
      this.detailsSection.loading = true;
      const json = await this.$http
        .get(window.CONFIG.precaution_api, {
          params: {
            ...this.global.filters,
            includeInfectionCase: true,
            includeClient: true,
            page: this.detailsSection.pagination.page,
            size: this.detailsSection.pagination.size,
          },
        })
        .then((response) => response.body)
        .catch((err) => {
          console.log({ err });
          if (err.status === 0) {
            this.detailsSection.loadingErrorDialog = true;
          }
          this.detailsSection.loading = false;
          return {
            page: 0,
            content: [],
            totalElements: 0,
          };
        });
      this.detailsSection.pagination.total = json.totalElements;
      this.detailsSection.data = json.content.map(this.generateDetailsTableData);
      this.detailsSection.loading = false;
    },
    async getSummary() {
      this.graphSection.loading = true;
      this.summarySection.loading = true;

      const summary = await this.$http
        .get(`${window.CONFIG.precaution_api}/summary/isolation-days`, {
          params: {
            ...this.global.filters,
            groupBy: this.graphSection.groupOptions[this.graphSection.groupBy],
            timeScale: this.graphSection.timeScale.toLowerCase(),
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        })
        .then((response) => response.body)
        .catch(() => {
          this.summarySection.loading = false;
          this.graphSection.loading = false;
          return [];
        });
      const [groups, periods] = this.createSummary(summary);
      this.summarySection.exportModels = { groups, periods };
      this.generateSummaryTableData(groups, periods);
      this.generateGraphData(groups, periods);
      this.graphSection.loading = false;
      this.summarySection.loading = false;
    },
    async refreshAllData() {
      if (this.detailsTableReady) {
        this.getDetails();
      }
      this.getSummary();
    },
    editPrecaution(precaution) {
      this.editor.data = precaution;
      this.editor.visible = true;
    },
    hideEditPrecaution() {
      this.editor.visible = false;
      this.editor.precaution = null;
      this.refreshAllData();
    },
  },
  async created() {
    console.clear();
    this.canModify = auth.canModify();
    this.global.facilities = this.$configStore.data.sites;
    this.graphSection.uniquePrecautionIndex = this.graphSection.filterKeys.indexOf("uniquePrecautionCount");
    await this.$http
      .get(`${window.CONFIG.precaution_api}/config`)
      .then((resp) => resp.json())
      .then((precautions) => {
        this.editor.precautions = precautions;
        this.global.precautions = util.arrayToObj(precautions, "id");
        this.getDetails();
      });
  },
};
</script>

<style>
.chart-title {
  text-align: center;
  margin-top: 5px;
  font-size: 18px !important;
  font-weight: bold;
}
</style>
